// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Confirmation from "../../components/app/dialogs/confirmation/confirmation";
import { PAGE_ORDERS } from "../../locales/pages/namespaces";
import useOrderAction from "../../hooks/order/useOrderAction";
import { useOrderData } from "../../hooks/order/useOrderData";
import { getRouteUrl } from "../../helpers/getRouteUrl";
import {
	ROUTE_PAGE_HOME,
	ROUTE_PAGE_ORDER,
	ROUTE_PAGE_ORDER_FINALIZE
} from "../../routers/routes";
import Box from "@mui/material/Box";
import {
	Alert,
	AlertTitle,
	Divider,
	Table, TableBody,
	TableCell, TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import Overview from "../../components/app/overview/overview";
import Textarea from "../../components/form/textarea/textarea";
import OrderPrices from "../../components/app/orderPrices/orderPrices";
import Button from "@mui/material/Button";
import ContentLoader from "../../components/general/contentLoader/contentLoader";
import ProductPreview from "../../components/app/productPreview/productPreview";
import { enqueueSnackbar } from "notistack";
import '../orderEdit/orderEdit.css'
import { OrderStatus } from "../../helpers/constants";
import useUserData from "../../hooks/user/useUserData";
import { LoadingButton } from "@mui/lab";
import useOrderDetailsData from "../../hooks/order/useOrderDetailsData";

type Props = {};

const OrderFinalize = (props: Props) => {
	const { id } = useParams()
	const history = useHistory();
	const { t } = useTranslation(PAGE_ORDERS)
	const {
		confirmOrder,
		deleteOrder,
		getActiveOrder,
		getOrderDetails,
		checkForPendingOrderItems,
		toggleOrderConfirmation
	} = useOrderAction()
	
	const {
		loading,
		totalProductsInCart,
		orderCompleted,
		orderNoLongerExists,
		activeOrder,
		loadingSubmitting,
		pendingOrderItems,
		loadingPendingItems,
		openOrderConfirmation
	} = useOrderData()
	const { maxAllowedProductsInCart } = useUserData()
	const { order } = useOrderDetailsData(id)
	// const order = useMemo(() => (activeOrder.length > 0 ? activeOrder[0] : null), [activeOrder])
	const [notes, setNotes] = useState(order?.notes)
	
	useEffect(() => {
		if (id !== 'undefined') getOrderDetails(id)
	}, [getOrderDetails, id])
	
	// useEffect(() => {
	// 	getActiveOrder()
	// }, [getActiveOrder])
	//
	useEffect(() => {
		if (orderCompleted) {
			history.push(getRouteUrl(ROUTE_PAGE_HOME))
		}
	}, [history, id, orderCompleted])
	
	useEffect(() => {
		if (orderNoLongerExists) {
			getActiveOrder()
			history.push(getRouteUrl(ROUTE_PAGE_HOME))
		}
	}, [getActiveOrder, history, orderNoLongerExists])
	
	useEffect(() => {
		if (totalProductsInCart > 0 && activeOrder.length > 0 && id === 'undefined') {
			history.push(getRouteUrl(ROUTE_PAGE_ORDER_FINALIZE, { id: activeOrder[0]?.id }))
		}
	}, [activeOrder, history, id, totalProductsInCart])
	
	useEffect(() => {
		setNotes(order?.notes)
	}, [order?.notes])
	
	const sendOrder = (values) => {
		if (totalProductsInCart > maxAllowedProductsInCart) {
			enqueueSnackbar(t('max_allowed_products', { maxAllowedProductsInCart: maxAllowedProductsInCart }), {
				variant: 'warning',
				autoHideDuration: 3000
			})
		} else {
			if (order?.products && order.products.length > 0) {
				checkForPendingOrderItems(id)
			} else {
				enqueueSnackbar(t('Place at least one product in your cart to submit your order!'), {
					variant: 'warning',
					transitionDuration: 2000
				})
			}
		}
	}
	
	const handleDelete = () => {
		deleteOrder(id)
	}
	
	const noProducts = useMemo(() => (
		<Box p={1}
			 sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
			<Typography
				pb={1}
				variant={'h2'}
				component={'div'}
				sx={{
					textAlign: 'center'
				}}
			>
				{t('No products found in your cart!')}
			</Typography>
			<Button
				component={Link}
				to={getRouteUrl(ROUTE_PAGE_HOME)}
				variant={'outlined'}
			>
				{t('Return to home')}
			</Button>
		</Box>
	), [t])
	
	const pendingItemsList = useMemo(() => (
		<Box>
			<Typography
				sx={{ textAlign: 'center' }}
				variant="h6" component={'div'} color={'primary'}
			>
				{t('Tire codes in pending order')}
			</Typography>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{backgroundColor: '#f0f8ff', pr: 1, pl: 0.2, fontWeight: 'bold'}}>{t('Tire code')}</TableCell>
							<TableCell sx={{backgroundColor: '#f0f8ff',  pr: 0.2, fontWeight: 'bold', textAlign: 'right'}}>{t('Pending number')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{pendingOrderItems.map(i => (
							<TableRow key={i.id}>
								<TableCell sx={{ pr: 1, pl: 0.2, wordWrap: 'break-word'}}>
									{i.description}
								</TableCell>
								<TableCell sx={{textAlign: 'right', pr: 0.2}}>
									{i.numberOfPending}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	), [pendingOrderItems, t])
	
	if ((!order && totalProductsInCart === 0 && !loading) || id === 'undefined') {
		return noProducts
	}

	return !loading && order && id !== 'undefined' ? (
		<>
			{order && order?.status !== OrderStatus.Submitted ? (
				<Box>
					<Box p={1}>
						<Typography
							pb={1}
							variant={'h2'}
							component={'div'}
							sx={{
								textAlign: 'center'
							}}
						>
							{t('Order Preview')}
						</Typography>
						<Overview order={order} handleDelete={handleDelete}/>
					</Box>
					{order?.removedProducts?.length > 0 && (
						<Box>
							<Alert severity="warning">
								<AlertTitle><strong>{t('These products are no longer available and have been removed from your cart!')}</strong></AlertTitle>
								<Box display={'flex'} flexDirection={'column'}>
									{order.removedProducts.map(product => (
										<Typography
											pb={1}
											variant={'body'}
										>
											<strong>{product?.description}</strong>
										</Typography>
									))}
								</Box>
							</Alert>
						</Box>
					)}
					<Box p={4} sx={{ backgroundColor: '#FFFFFF' }}>
						{order?.products && (
							order.products.map(product => (
								<ProductPreview
									key={`product-${product.id}`}
									product={{ ...product }}
									orderId={id}
								/>
							))
						)}
					</Box>
					<Box p={4} className={'order-info'}>
						<Box sx={{ width: { xs: '100%', sm: '100%', md: '50%' } }}>
							<Typography
								pb={1}
								variant={'h4'}
								component={'div'}
							>
								{t('Notes')}
							</Typography>
							<Textarea
								value={notes}
								onChange={(evt) => setNotes(evt.target.value)}
								name={'notes'}
								placeholder={t('Order notes...')}
								minRows={5}
							/>
						</Box>
						<Box pt={1} sx={{ width: { xs: '100%', sm: '100%', md: '50%' } }}>
							<OrderPrices order={order}/>
							<Divider/>
							<Box mt={1} className={'submit-order'}>
								<LoadingButton
									size={"medium"}
									variant={'contained'}
									color={'success'}
									onClick={sendOrder}
									loading={loadingPendingItems}
								>
									{t('SEND ORDER')}
								</LoadingButton>
							</Box>
						</Box>
					</Box>
					<Confirmation
						maxWidth={'sm'}
						loading={loadingSubmitting}
						open={openOrderConfirmation}
						handleDialog={(newValue) => toggleOrderConfirmation(newValue)}
						title={t('Do you wish to finalize your order?')}
						onYes={() => {
							confirmOrder({
								orderId: id,
								notes: notes,
								isConfirmed: 1
							})
						}}
						onNo={() => toggleOrderConfirmation(false)}
						yesBtnText={t('Final Submission')}
						noBtnText={t('Cancel')}
					>
						
						{pendingOrderItems.length > 0 && pendingItemsList}
					</Confirmation>
				</Box>
			) : (
				<Box p={1}
					 sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
					<Typography
						pb={1}
						variant={'h2'}
						component={'div'}
						sx={{
							textAlign: 'center'
						}}
					>
						{t('This is a completed order! Check order details bellow!')}
					</Typography>
					<Button
						component={Link}
						to={getRouteUrl(ROUTE_PAGE_ORDER, { id: id })}
						variant={'outlined'}
					>
						{t('See Order Details')}
					</Button>
				</Box>
			)}
		</>
	) : (
		<ContentLoader loading={loading}/>
	)
};

export default OrderFinalize