import {combineReducers} from '@reduxjs/toolkit';
import {fromJS} from "immutable";
import {ENABLED_MODULES} from "../../App";
import {connectRouter} from "connected-react-router";
import history from "../history";

const importedReducers = ENABLED_MODULES.loadReducers();

const createReducer = ({initialState, handlers}) => (state = fromJS(initialState), action) => (
	handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state
);

const appReducers = combineReducers(
	Object
	.keys(importedReducers)
	.reduce((result, key) => ({
		...result,
		[key]: createReducer(importedReducers[key])
	}), {
		router: connectRouter(history)
	}));

export const reducers = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		Object.keys(state).forEach(key => {
			if (![
				//add reducers to keep on logout
			].includes(key)) {
				state[key] = undefined;
			}
		});
	}
	return appReducers(state, action);
};