import * as ActionTypes from '../actions/types'
import * as Actions from '../actions/actions'
import {requestCreator} from '../helpers/requestCreator'
import {API, PortalToken} from "../helpers/constants";
import {getRouteUrl} from "../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDERS} from "../routers/routes";
import history from '../store/history'

const checkAPIConnectivity = requestCreator({
	nonApiUrl: true,
	url: API + '/portcheck.txt?t=' + (new Date()).getTime(),
	method: 'GET',
	onSuccess: Actions.checkAPIConnectivitySucceeded,
	onFailure: Actions.checkAPIConnectivityFailed
})

// User --------------------------------------------------------
const userLogout = requestCreator({
	url: '/logout',
	method: 'GET',
	onSuccess: Actions.userLogoutSucceeded,
	onFailure: Actions.userLogoutFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
		'TokenGuid': PortalToken
	}
})

const userLogin = requestCreator({
	url: '/login',
	method: 'POST',
	onSuccess: Actions.userLoginSucceeded,
	onFailure: Actions.userLoginFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
		'TokenGuid': PortalToken
	}
})

const requestResetPassword = requestCreator({
	url: '/request/new-password',
	method: 'GET',
	onSuccess: Actions.requestResetPasswordSucceeded,
	onFailure: Actions.requestResetPasswordFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
		'TokenGuid': PortalToken
	}
})

const resetPassword = requestCreator({
	url: '/store/new/password',
	method: 'POST',
	onSuccess: Actions.resetPasswordSucceeded,
	onFailure: Actions.resetPasswordFailed,
	headers: {
		'Content-Type': 'multipart/form-data',
		'TokenGuid': PortalToken
	}
})

const getUserStores = requestCreator({
	url: '/b2b/user/addresses',
	method: 'GET',
	onSuccess: Actions.getUserStoresSucceeded,
	onFailure: Actions.getUserStoresFailed,
})

const getUserDetails = requestCreator({
	url: '/b2b/user/data',
	method: 'GET',
	onSuccess: Actions.getUserDetailsSucceeded,
	onFailure: Actions.getUserDetailsFailed,
})

const getDailyCode = requestCreator({
	url: '/b2b/daily/password/store',
	method: 'POST',
	onSuccess: Actions.getDailyCodeSucceeded,
	onFailure: Actions.getDailyCodeFailed,
})

// Products --------------------------------------------------------
const getSearchResults = requestCreator({
	url: '/b2b/items/search',
	method: 'POST',
	onSuccess: Actions.getSearchResultsSucceeded,
	onFailure: Actions.getSearchResultsFailed,
})

const getSearchResultsFilters = requestCreator({
	url: '/b2b/items/attributes',
	method: 'POST',
	onSuccess: Actions.getSearchResultsFiltersSucceeded,
	onFailure: Actions.getSearchResultsFiltersFailed,
})

// Orders --------------------------------------------------------
const getActiveOrder = requestCreator({
	url: '/b2b/user/order',
	method: 'GET',
	onSuccess: Actions.getActiveOrderSucceeded,
	onFailure: Actions.getActiveOrderFailed,
})

const createOrder = requestCreator({
	url: '/b2b/order/store',
	method: 'POST',
	onSuccess: Actions.createOrderSucceeded,
	onFailure: Actions.createOrderFailed,
	headers: {
		'Content-Type': 'application/json'
	}
})

const updateOrder = requestCreator({
	url: '/b2b/order/update/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.updateOrderSucceeded,
	onFailure: Actions.updateOrderFailed,
})

const completeOrder = requestCreator({
	url: '/b2b/order/complete/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.completeOrderSucceeded,
	onFailure: Actions.completeOrderFailed,
})

const getOrderDetails = requestCreator({
	url: '/b2b/order/details/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getOrderDetailsSucceeded,
	onFailure: Actions.getOrderDetailsFailed,
})

const deleteOrder = requestCreator({
	url: '/b2b/order/remove/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.deleteOrderSucceeded,
	onFailure: Actions.deleteOrderFailed,
})

const deleteOrderSucceeded = requestCreator({
	overrideTask: async () => {
		history.push(getRouteUrl(ROUTE_PAGE_ORDERS))
	}
})

const removeProductFromOrder = requestCreator({
	url: '/b2b/order/remove/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.removeProductFromOrderSucceeded,
	onFailure: Actions.removeProductFromOrderFailed,
})

const checkForPendingOrderItems = requestCreator({
	url: '/b2b/pending/items/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.checkForPendingOrderItemsSucceeded,
	onFailure: Actions.checkForPendingOrderItemsFailed,
})

// Warranties ---------------------------------------------
const getWarrantyDetails = requestCreator({
	url: '/warranties/details/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getWarrantyDetailsSucceeded,
	onFailure: Actions.getWarrantyDetailsFailed,
})

const createWarranty = requestCreator({
	url: '/b2b/guarantee/store/:id',
	hasPathParameters: ['id'],
	method: 'POST',
	onSuccess: Actions.createWarrantySucceeded,
	onFailure: Actions.createWarrantyFailed,
})

// Announcements -------------------------------------------------------
const getMessages = requestCreator({
	url: '/messages/b2bIndexTable',
	method: 'POST',
	onSuccess: Actions.getMessagesSucceeded,
	onFailure: Actions.getMessagesFailed,
})

const setMessageViewed = requestCreator({
	url: '/messages/setViewed',
	method: 'POST',
	onSuccess: Actions.setMessageViewedSucceeded,
	onFailure: Actions.setMessageViewedFailed,
})

const getMessageAttachments = requestCreator({
	url: '/messages/attachments/:id',
	hasPathParameters: ['id'],
	method: 'GET',
	onSuccess: Actions.getMessageAttachmentsSucceeded,
	onFailure: Actions.getMessageAttachmentsFailed,
})

const getCountUnreadMessages = requestCreator({
	url: '/messages/b2bAnnouncements',
	method: 'POST',
	onSuccess: Actions.getCountUnreadMessagesSucceeded,
	onFailure: Actions.getCountUnreadMessagesFailed,
	
})

const getMessageCategories = requestCreator({
	url: '/messages/b2bAnnouncements',
	method: 'POST',
	onSuccess: Actions.getMessageCategoriesSucceeded,
	onFailure: Actions.getMessageCategoriesFailed,
})

const getImportantAnnouncement = requestCreator({
	url: '/b2b/popup/message',
	method: 'POST',
	onSuccess: Actions.getImportantAnnouncementSucceeded,
	onFailure: Actions.getImportantAnnouncementFailed,
})

// Reports -------------------------------------------------------
const getBudgetAndGoalsReport = requestCreator({
	url: '/b2b/statistics',
	method: 'GET',
	onSuccess: Actions.getBudgetAndGoalsReportSucceeded,
	onFailure: Actions.getBudgetAndGoalsReportFailed
})

const getCustomerCard = requestCreator({
	url: '/b2b/customer/card',
	method: 'POST',
	onSuccess: Actions.getCustomerCardSucceeded,
	onFailure: Actions.getCustomerCardFailed
})

const getSalesReportFilters = requestCreator({
	url: '/b2b/statistics/bought/filters',
	method: 'GET',
	onSuccess: Actions.getSalesReportFiltersSucceeded,
	onFailure: Actions.getSalesReportFiltersFailed
})

const getSalesReportData = requestCreator({
	url: '/b2b/statistics/bought',
	method: 'GET',
	onSuccess: Actions.getSalesReportDataSucceeded,
	onFailure: Actions.getSalesReportDataFailed
})

const requireDailyCode = requestCreator({
	url: '/b2b/daily/password/check',
	method: 'POST',
	onSuccess: Actions.requiredDailyCodeSucceeded,
	onFailure: Actions.requiredDailyCodeFailed
})

// Banners -------------------------------------------------------
const getBanners = requestCreator({
	url: '/b2b/dashboard/banners',
	method: 'GET',
	onSuccess: Actions.getBannersSucceeded,
	onFailure: Actions.getBannersFailed,
})

const requestHandler = {
	// [ActionTypes.CHECK_API_CONNECTIVITY]: checkAPIConnectivity,
	// User -----------------------------------------------
	[ActionTypes.USER_LOGOUT]: userLogout,
	[ActionTypes.USER_LOGIN]: userLogin,
	[ActionTypes.REQUEST_RESET_PASSWORD]: requestResetPassword,
	[ActionTypes.RESET_PASSWORD]: resetPassword,
	[ActionTypes.GET_USER_STORES]: getUserStores,
	[ActionTypes.GET_USER_DETAILS]: getUserDetails,
	[ActionTypes.GET_DAILY_CODE]: getDailyCode,
	// Products -------------------------------------------
	[ActionTypes.GET_SEARCH_RESULTS]: getSearchResults,
	[ActionTypes.GET_SEARCH_RESULTS_FILTERS]: getSearchResultsFilters,
	// Orders ---------------------------------------------
	[ActionTypes.GET_ACTIVE_ORDER]: getActiveOrder,
	[ActionTypes.GET_ORDER_DETAILS]: getOrderDetails,
	[ActionTypes.CREATE_ORDER]: createOrder,
	[ActionTypes.UPDATE_ORDER]: updateOrder,
	[ActionTypes.COMPLETE_ORDER]: completeOrder,
	[ActionTypes.DELETE_ORDER]: deleteOrder,
	[ActionTypes.DELETE_ORDER_SUCCEEDED]: deleteOrderSucceeded,
	[ActionTypes.REMOVE_PRODUCT_FROM_ORDER]: removeProductFromOrder,
	[ActionTypes.CHECK_FOR_PENDING_ORDER_ITEMS]: checkForPendingOrderItems,
	// Messages ----------------------------------------------
	[ActionTypes.GET_MESSAGES]: getMessages,
	[ActionTypes.SET_MESSAGE_VIEWED]: setMessageViewed,
	[ActionTypes.GET_MESSAGE_ATTACHMENTS]: getMessageAttachments,
	[ActionTypes.GET_COUNT_UNREAD_MESSAGES]: getCountUnreadMessages,
	[ActionTypes.GET_MESSAGE_CATEGORIES]: getMessageCategories,
	[ActionTypes.GET_IMPORTANT_ANNOUNCEMENT]: getImportantAnnouncement,
	// Warranties --------------------------------------------
	[ActionTypes.GET_WARRANTY_DETAILS]: getWarrantyDetails,
	[ActionTypes.CREATE_WARRANTY]: createWarranty,
	// Reports --------------------------------------------
	[ActionTypes.GET_BUDGET_AND_GOALS_REPORT]: getBudgetAndGoalsReport,
	[ActionTypes.GET_CUSTOMER_CARD]: getCustomerCard,
	[ActionTypes.GET_SALES_REPORT_FILTERS]: getSalesReportFilters,
	[ActionTypes.GET_SALES_REPORT_DATA]: getSalesReportData,
	[ActionTypes.REQUIRE_DAILY_CODE]: requireDailyCode,
	// Banners --------------------------------------------
	[ActionTypes.GET_BANNERS]: getBanners,
}

export default requestHandler
