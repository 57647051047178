// @flow
import React, {useEffect, useState} from 'react';
import {enqueueSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import Form from "../../components/app/forms/login/form";
import Box from "@mui/material/Box";
import './login.css'
import {Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import Promo from "../../components/app/promo/promo";
import LanguageSelect from "../../components/general/languageSelect/languageSelect";
import Button from "@mui/material/Button";
import useLogin from "../../hooks/login/useLogin";
import ForgotPassword from "../../components/app/dialogs/forgotPassword/forgotPassword";
import DailyCode from "../../components/app/dialogs/dailyCode/dailyCode";
import UserAddresses from "../../components/app/dialogs/userAddresses/userAddresses";

const Login = () => {
	const {t} = useTranslation(PAGE_LOGIN)
	const {setUserMessage, checkAPIConnectivity} = useUserAction()
	const {loading, message, isLoggedIn, loadingStores} = useUserData()
	const {formik} = useLogin()
	const [openForgotPass, setOpenForgotPass] = useState(false)
	const [openDailyCode, setOpenDailyCode] = useState(false)
	const [openSelectAddress, setOpenSelectAddress] = useState(false)
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	
	useEffect(() => {
		if (!isLoggedIn) checkAPIConnectivity()
	}, [checkAPIConnectivity, isLoggedIn])

	useEffect(() => {
		setOpenSelectAddress(isLoggedIn)
	}, [isLoggedIn])
	
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setUserMessage(null)
		}
	}, [message, setUserMessage, t])
	
	return (
		<Box
			className={"login"}
		>
			<Grid container sx={{height: '100%'}}>
				{!matches && (
					<Grid
						sx={{
							borderRight: {
								sm: 'none',
								md: '2px solid #fbc324'
							},
						}}
						item
						sm={6}
						md={6}
						lg={8}
					>
						<Promo/>
					</Grid>
				)}
				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={4}
				>
					<Box className={'login-right'} sx={{backgroundColor: 'primary'}}>
						<Box mt={1} mb={1} sx={{position: 'absolute', top: 0, right: '60px'}}>
							<LanguageSelect/>
						</Box>
						{/*<Logo*/}
						{/*	style={{*/}
						{/*		height: '200px',*/}
						{/*		width: 'auto'*/}
						{/*	}}*/}
						{/*/>*/}
						<Box pt={2}>
							<Typography
								variant="h1"
								color={'#FFFFFF'}
								sx={{textShadow: '0px 11px 10px rgba(0,0,0,0.8)', letterSpacing: 1.6, textAlign: 'center'}}
							>
								{t("STRIVE TO SERVE")}
							</Typography>
						</Box>
						<Form
							formik={formik}
							loading={loading}
						/>
						<Box pt={2}>
							<Typography
								component={Button}
								onClick={() => setOpenDailyCode(true)}
								variant="body1"
								color={'secondary'}
							>
								{t("Get your daily code.")}
							</Typography>
						</Box>
						<Box>
							<Typography
								component={Button}
								onClick={() => setOpenForgotPass(true)}
								variant="body1"
								color={'secondary'}
							>
								{t("Forgot password? Click here.")}
							</Typography>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<ForgotPassword
				open={openForgotPass}
				handleDialog={(newValue) => setOpenForgotPass(newValue)}
			/>
			<DailyCode
				open={openDailyCode}
				handleDialog={(newValue) => setOpenDailyCode(newValue)}
			/>
			<UserAddresses
				open={openSelectAddress}
				handleDialog={(newValue) => setOpenSelectAddress(newValue)}
				loadingStores={loadingStores}
			/>
		</Box>
	)
}

export default Login