// @flow
import React, {useMemo, useState} from 'react';
import {Divider, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import './overview.css'
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../../locales/pages/namespaces";
import OrderStatus from "../orderStatus/orderStatus";
import Button from "@mui/material/Button";
import Confirmation from "../dialogs/confirmation/confirmation";
import useUserData from "../../../hooks/user/useUserData";
import {NumericFormat} from "react-number-format";
import {calculateOrderSums, convertActiveOrderProducts} from "../../../helpers/helpers";

type Props = {};

const Overview = (props: Props) => {
	const {
		order: {
			id,
			status,
			totalPriceWithTax,
			totalPriceWithoutTax,
			totalOrderTax,
			totalOrderDiscount,
			products
		},
		finalize,
		handleDelete = () => {
		}
	} = props
	const {t} = useTranslation(PAGE_ORDERS)
	const {thousandSeparator, decimalSeparator} = useUserData()
	const [deleteConfirm, setDeleteConfirm] = useState(false)
	const orderPrices = useMemo(() => {
		if (finalize) {
			return {
				sumWithTax: totalPriceWithTax,
				sumWithoutTax: totalPriceWithoutTax,
				sumDiscount: totalOrderDiscount,
				sumTax: totalOrderTax
			}
		} else {
			const orderProducts = convertActiveOrderProducts((products?.length > 0) ? products : [])
			return calculateOrderSums(orderProducts)
		}
	}, [products, totalOrderDiscount, totalOrderTax, totalPriceWithTax, totalPriceWithoutTax, finalize])
	
	return (
		<Box className={'order-overview'}>
			<Divider sx={{borderWidth: 2, borderColor: 'primary.main'}}/>
			<Box className={'overview-title'}>
				<Box className={'overview-info'} sx={{width: {xs: '100%', sm: 'auto'}}}>
					<Typography component={'div'} variant={'h5'}>
						{t('Code')}
					</Typography>
					<Typography component={'div'} variant={'overline'}>
						{id}
					</Typography>
				</Box>
				<Box className={'overview-info'} sx={{width: {xs: '100%', sm: 'auto'}}}>
					<Typography component={'div'} variant={'h5'}>
						{t('Status')}
					</Typography>
					<Typography component={'div'} variant={'overline'}>
						<OrderStatus status={status}/>
					</Typography>
				</Box>
				<Box className={'overview-info'} sx={{width: {xs: '100%', sm: 'auto'}}}>
					<Typography component={'div'} variant={'h5'}>
						{t('Total Discount')}
					</Typography>
					<Typography component={'div'} variant={'overline'}>
						<NumericFormat
							thousandSeparator={thousandSeparator}
							decimalSeparator={decimalSeparator}
							displayType="text"
							value={orderPrices?.sumDiscount}
						/> €
					</Typography>
				</Box>
				<Box className={'overview-info'} sx={{width: {xs: '100%', sm: 'auto'}}}>
					<Typography component={'div'} variant={'h5'}>
						{t('Total Cost (without tax)')}
					</Typography>
					<Typography component={'div'} variant={'overline'}>
						<NumericFormat
							thousandSeparator={thousandSeparator}
							decimalSeparator={decimalSeparator}
							displayType="text"
							value={orderPrices?.sumWithoutTax}
						/> €
					</Typography>
				</Box>
				<Box className={'overview-info'} sx={{width: {xs: '100%', sm: 'auto'}}}>
					<Typography component={'div'} variant={'h5'}>
						{t('Total Tax')}
					</Typography>
					<Typography component={'div'} variant={'overline'}>
						<NumericFormat
							thousandSeparator={thousandSeparator}
							decimalSeparator={decimalSeparator}
							displayType="text"
							value={orderPrices?.sumTax}
						/> €
					</Typography>
				</Box>
				<Box className={'overview-info'} sx={{width: {xs: '100%', sm: 'auto'}}}>
					<Typography component={'div'} variant={'h5'}>
						{t('Total Cost (with tax)')}
					</Typography>
					<Typography component={'div'} variant={'overline'}>
						<NumericFormat
							thousandSeparator={thousandSeparator}
							decimalSeparator={decimalSeparator}
							displayType="text"
							value={orderPrices?.sumWithTax}
						/> €
					</Typography>
				</Box>
				{!finalize && (
					<Box className={'overview-info'} sx={{width: {xs: '100%', sm: 'auto'}}}>
						<Button
							size={"medium"}
							variant={'contained'}
							color={'error'}
							onClick={() => setDeleteConfirm(true)}
						>
							{t('DELETE')}
						</Button>
					</Box>
				)}
			</Box>
			<Divider sx={{borderWidth: 2, borderColor: 'primary.main'}}/>
			<Confirmation
				open={deleteConfirm}
				handleDialog={(newValue) => setDeleteConfirm(newValue)}
				title={t('Delete order confirmation')}
				onYes={handleDelete}
				onNo={() => setDeleteConfirm(false)}
			>
				{t('Your order will be completely deleted. Please press YES to confirm.')}
			</Confirmation>
		</Box>
	);
};

export default Overview