//@flow
type Request = {
	url?: string,
	hasPathParameters?: Array<string>,
	method?: 'POST' | 'GET' | 'PATCH' | 'DELETE' | 'PUT',
	onSuccess?: Function,
	onFailure?: Function,
	headers?: Object,
	overrideTask?: Promise<(actionData: Object) => { data: Object | Array<Object> } | (actionData: Object) => void>,
}

type CreatedRequest = Request & {
	data: Object
}

export const requestCreator: CreatedRequest = (request: Request) => (data) => {
	return ({
		...request,
		data: data,
	})
}