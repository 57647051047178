const mode = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'development' : 'production'
export const configuration = window.GLOBAL_CONFIGURATION[mode]
export const API = mode === 'production' ? configuration.API_HOST[window.location.hostname] : configuration.API_HOST;
export const PortalToken = window.GLOBAL_CONFIGURATION.portalToken
export const MUI_LICENSE_KEY = '165e31ea195f48fc18e36a0a5d5b4310Tz05MTU1NyxFPTE3NDg2MTg3MzAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'

export const Languages = {
	EL: 'el',
	EN: 'en',
}

export const Permissions = {
	AccessAdmin: "ACCESS_ADMIN",
	AccessReports: "ACCESS_REPORTS",
	AccessSalesPerson: "ACCESS_SALESPERSON",
	AccessGuarantees: "ACCESS_GUARANTEES",
	AccessMakWheels: "ACCESS_MAK_WHEELS",
}

export const AvailabilityStatus = {
	Available: 0,
	Limited: 1,
	OutOfStock: 2,
}

export const ReadStatus = {
	Any: 0,
	Read: 1,
	Unread: 2,
}

export const MESSAGE_ACTION_TYPE_B2B = 8

export const AnnouncementTypes = {
	All: 0,
	Alert: 43,
	Warning: 44,
	Error: 45,
	Info: 46,
	Memo: 47,
	Report: 48,
	Eshop: 49,
	Company: 50,
	Newsletter: 51,
}

export const OrderStatus = {
	Submitted: 'Submitted',
	InProgress: 'In Progress',
	Canceled: 'Canceled',
	'Έχει εκτελεστεί Πλήρως': 'Έχει εκτελεστεί Πλήρως'
}

export const InvoiceTypes = {
	'ΤΙΜΟΛΟΓΙΟ ΠΩΛΗΣΗΣ': 'ΤΙΜΟΛΟΓΙΟ ΠΩΛΗΣΗΣ',
	'ΠΙΣΤΩΤΙΚΟ ΤΙΜΟΛΟΓΙΟ (ΔΑ)': 'ΠΙΣΤΩΤΙΚΟ ΤΙΜΟΛΟΓΙΟ (ΔΑ)',
	'ΠΙΣΤΩΤΙΚΟ ΤΙΜΟΛΟΓΙΟ ΕΚΠΤΩΣΗΣ': 'ΠΙΣΤΩΤΙΚΟ ΤΙΜΟΛΟΓΙΟ ΕΚΠΤΩΣΗΣ',
	'ΠΙΣΤΩΤΙΚΟ ΤΙΜΟΛΟΓΙΟ ': 'ΠΙΣΤΩΤΙΚΟ ΤΙΜΟΛΟΓΙΟ '
}

export const WarrantyStatus = {
	Initialized: 'Initialized',
	Activated: 'Activated',
	Pending: 'Pending Approval',
	Approved: 'Approved',
	Rejected: 'Rejected',
	ActivationTimeExpired: 'Activation Time Expired',
	Expired: 'Expired'
}

export const timeLimitToRequireDailyCode = 360 // minutes, about 6 hours