import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "../../helpers/constants";
import { handleAxiosErrors } from "../../helpers/errorHandling";
import useUserData from "../user/useUserData";

const useMacWheelsLogin = () => {
	const {token, selectedStore} = useUserData()
	const {t} = useTranslation()
	const [loading, setLoading] = useState(false)
	const macWheelsLogin = () => {
		setLoading(true)
		axios({
			method: 'post',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/el/b2b/makwheels/login`,
			data: {
				AddressId: selectedStore.id
			}
		}).then(response => {
			setLoading(false)
			const url = response.data
			if(url) {
				window.location.href = url
			}
		}).catch((error) => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Unable to establish connection. Please try again later!'), {
				variant: 'error',
				autoHideDuration: 1000,
			}));
		})
	}
	return {
		loading,
		macWheelsLogin
	}
}


export default useMacWheelsLogin